@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Federant&family=Inter:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@500&family=Poppins&display=swap");

.svg-animation {
  animation-name: bouncing;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.active {
  @apply opacity-100 text-[#59C4CA] scale-[1.3];
}

@keyframes bouncing {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 768px) {
  .grecaptcha-badge {
    visibility: hidden;
  }
}
